import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MainComponent } from '../app/main/main.component';
// import { AddproductComponent } from '../app/addproduct/addproduct.component';
import { AllproductsComponent } from '../app/allproducts/allproducts.component';
import { ContactusComponent } from '../app/contactus/contactus.component'
import {ProductDetailComponent} from '../app/product-detail/product-detail.component'
const routes: Routes = [
  { path: '', component: MainComponent},
  // { path: 'addproduct', component: AddproductComponent},
  { path: 'allproducts', component: AllproductsComponent},
  { path: 'contactus', component: ContactusComponent},
  { path: 'contactus', component: ContactusComponent},
  {path: 'product/:id' , component: ProductDetailComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
