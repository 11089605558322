import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service'
import  $ from 'jquery';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-allproducts',
  templateUrl: './allproducts.component.html',
  styleUrls: ['./allproducts.component.css']
})
export class AllproductsComponent implements OnInit {
  productCategory = [];
  products = [];
  obj = {};
  JSON = JSON;
  baseUrl = environment.ApiPath;

  constructor(private commonService: CommonService ) { }

  ngOnInit() {
    setTimeout(function(){
      $("#Trains").addClass('active');
      $("#Trainstab").addClass('show active')
    }, 1000);
    window.scrollTo(0, 0);
    this.commonService.getProductListBycategory().subscribe(data => {
      data.data.categoryList.forEach(element => {
        let Obj = {
          idname: element.split(' ').join('').split('&').join(''),
          name:element,
          items: data.data.productgroup[element]
        }
        this.productCategory.push(Obj);
      });
    });
  }

}

// import { Component, OnInit } from '@angular/core';
// import { CommonService } from '../common.service'
// import  $ from 'jquery';

// // // import { jquery } from '../../../node_modules/jquery' 
// // let $ = require('../../../node_modules/jquery/dist/jquery.min.js' )
// // // import * as $ from 'jquery'  
// // // declare var $: any;
// @Component({
//   selector: 'app-allproducts',
//   templateUrl: './allproducts.component.html',
//   styleUrls: ['./allproducts.component.css']
// })
// export class AllproductsComponent implements OnInit {
//   productCategory = [];
//   products = [];
//   constructor(private commonService: CommonService ) { }

//   ngOnInit() {
//     this.commonService.getCategoryList().subscribe(data => { 
//       // console.log("this.products12312312",$("#"+this.productCategory[0]._id)); 
//       this.productCategory = data.data;
//     }); 
//     // this.commonService.getProductListBycategory().subscribe(data => {
//     //    this.products = data.data
//     //    for (let i = 0; i < this.products.length; i++) {
//     //     // var dyamicname = this.products[i]._id;
//     //     console.log(this.products[i].products);
//     //   }
      
//     // });
//     this.commonService.getProductListBycategory().subscribe(data => {
//       data.data.categoryList.forEach(element => {
//         let Obj = {
//           name:element,
//           items: data.data.productgroup[element]
//         }
//         this.productCategory.push(Obj);
//       });
//     });
    // setTimeout(function(){
    //   $("#5e0a31eeb26d4010ea805b5d").addClass('active');
    //   $("#5e0a31eeb26d4010ea805b5dtab").addClass('show')
    // }, 1000);
//     // $('#fulldiv').css('height',$(window).height());
//     console.log("this.products",this.products,this.productCategory);    
//   }

// }
