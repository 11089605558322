import { Component, OnInit } from '@angular/core';
import { Product } from '../product';
import { Observable } from "rxjs";
import { CommonService } from '../common.service'
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
declare var $: any;

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css']
})
export class MainComponent implements OnInit {
  products: Observable<Product[]>;
  productsArray = [];
  baseUrl = environment.ApiPath;

  constructor(private http: HttpClient, private commonService: CommonService) { }

  ngOnInit() {
    this.commonService.getMainProductList().subscribe(data => { this.productsArray = data.data });
    setTimeout(function () {
      $(".banner-slider").slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: true,
        dots: false,
        autoplay: true,
        autoplaySpeed: 1000,
        centerMode: true,
        // speed: 500,
        // fade: true,
        prevArrow: $('.bannernew .slider-prev'),
        nextArrow: $('.bannernew .slider-next'),
        centerPadding: '80px',
        focusOnSelect: true,
        responsive: [{
            breakpoint: 1400,
            settings: {
              slidesToShow: 2
            }
          },
          {
            breakpoint: 991,
            settings: {
              variableWidth: false,
              centerPadding: '0px',
              centerMode: false,
              arrows: false,
              autoplay: true,
              slidesToShow: 1
            }
          }
        ]
      });
      $('.slider').slick({
        autoplay:true,
        autoplaySpeed:5000,
        arrows:true,
        centerMode:true,
        slidesToShow:4,
        slidesToScroll:1,
        responsive: [{
          breakpoint: 1400,
          settings: {
            slidesToShow: 3
          }
        },
        {
          breakpoint: 991,
          settings: {
            variableWidth: false,
            centerPadding: '0px',
            centerMode: false,
            arrows: false,
            autoplay: true,
            slidesToShow: 1
          }
        }
      ]
        });
      // $('.slider').slick({
      //   slidesToShow: 1,
      //   centerPadding: '80px',
      //   autoplay: true,
      //   slidesToScroll: 3,
      //   dots: false,
      //   infinite: true,
      //   speed: 500,
      //   fade: true,
      // });
      // $('.slider').slick({
      //   slidesToShow: 1,
      //   slidesToScroll: 1,
      //   draggable: true,
      //   autoplay: true,
      //   autoplaySpeed: 700,
      //   arrows: false,
      //   dots: false,
      //   fade: true,
      //   speed: 500,
      //   infinite: true,
      //   cssEase: 'ease-in-out',
      //   // touchThreshold: 100
      // })
      // $(".banner-sliderslidernew").not('.slick-initialized').slick({
      //   slidesToShow: 2,
      //   slidesToScroll: 1,
      //   arrows: true,
      //   dots: false,
      //   centerMode: true,
      //   centerPadding: '80px',
      //   focusOnSelect: true,
      //   responsive: [{
      //       breakpoint: 1400,
      //       settings: {
      //         slidesToShow: 2
      //       }
      //     },
      //     {
      //       breakpoint: 991,
      //       settings: {
      //         variableWidth: false,
      //         centerPadding: '0px',
      //         centerMode: false,
      //         arrows: false,
      //         autoplay: true,
      //         slidesToShow: 1
      //       }
      //     }
      //   ]
      // });
      // $('.banner-slider').on('afterChange', function (event, slick, currentSlide, nextSlide) {
      //   $(".bannernew .current-slide span").text(currentSlide + 1);
      // });

    }, 50);
  }
}
