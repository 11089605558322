import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Category } from '../catgory';
import { Observable } from "rxjs";
import { HttpClient } from '@angular/common/http';
import * as ClassicEditor from '@ckeditor/ckeditor5-build-classic';

@Component({
  selector: 'app-addproduct',
  templateUrl: './addproduct.component.html',
  styleUrls: ['./addproduct.component.css']
})
export class AddproductComponent implements OnInit {
  addproductForm: FormGroup;
  loading = false;
  submitted = false;
  productCategory = [];
  fileData: File = null;
  previewUrl:any = null;
  fileUploadProgress: string = null;
  uploadedFilePath: string = null;
  marked = false;
  theCheckbox = false;
  theCheckbox2 = false;
  public Editor = ClassicEditor;
  images = [];
  filesToUpload: Array<File> = [];
  categories: Observable<Category[]>;
  constructor(private formBuilder: FormBuilder, private router: Router,private http: HttpClient ,  private commonService: CommonService) { }

  ngOnInit() {
    this.addproductForm = this.formBuilder.group({
      productName: ['', Validators.required],
      productId: ['', Validators.required],
      description: ['', Validators.required],
      isMainProduct: ['', Validators.required],
      isNewProduct: ['', Validators.required],
      dimensions: [''],
      power: ['', Validators.required],
      videoUrl: ['', Validators.required],
      combine: [''],
      track: [''],
      capacity: ['', Validators.required],
      imageData: [null],
      productCategory: ['']
    });
    // this.imageData
    this.commonService.getCategoryList().subscribe(data => { this.productCategory = data.data});
  }
  // fileProgress(fileInput: any) {
  //   this.fileData = <File>fileInput.target.files[0];
  //   this.preview();
  // }
  fileProgress(event) {
    // this.filesToUpload.push(event.target.files);
    for (let i = 0; i < event.target.files.length ; i++) {
      this.filesToUpload.push(event.target.files[i]);
    }
    console.log("this.filesToUpload",this.filesToUpload);
  }

  preview() {
    // Show preview 
    var mimeType = this.fileData.type;
    if (mimeType.match(/image\/*/) == null) {
      return;
    }
    var reader = new FileReader();      
    reader.readAsDataURL(this.fileData); 
    reader.onload = (_event) => { 
      this.previewUrl = reader.result; 
    }
  }
  onSubmit() {
    this.submitted = true;
    // console.log("this.images",this.images,JSON.stringify(this.images));
    const formData = new FormData();
    const files: Array<File> = this.filesToUpload;
    console.log("files------------------",files);
    // formData.append('file', this.fileData);
    // formData.append('imageData', JSON.stringify(this.images));
    for(let i =0; i < files.length; i++){
      // formData.append("file[]", this.images[i]);
      formData.append("image", files[i], files[i]['name']);
    }
    formData.append('productName', this.addproductForm.value.productName);
    formData.append('productId', this.addproductForm.value.productId);
    formData.append('description', this.addproductForm.value.description);
    formData.append('productCategory', this.addproductForm.value.productCategory);
    formData.append('isMainProduct', this.addproductForm.value.isMainProduct);
    formData.append('videoUrl', this.addproductForm.value.videoUrl);
    formData.append('track', this.addproductForm.value.track);
    formData.append('combine', this.addproductForm.value.combine);   
    formData.append('power', this.addproductForm.value.power);
    formData.append('dimensions', this.addproductForm.value.dimensions);
    formData.append('capacity', this.addproductForm.value.capacity);
    console.log('form data variable :   '+ formData.toString());
    // var formData = {
    //   productName: this.addproductForm.value.productName,
    //   file:this.images,
    //   productId: this.addproductForm.value.productId,
    //   description:this.addproductForm.value.description,
    //   productCategory:this.addproductForm.value.productCategory,
    //   isMainProduct:this.addproductForm.value.isMainProduct,
    //   videoUrl:this.addproductForm.value.videoUrl,
    //   track:this.addproductForm.value.track,
    //   combine: this.addproductForm.value.combine,
    //   power:this.addproductForm.value.power,
    //   dimensions:this.addproductForm.value.dimensions,
    //   capacity: this.addproductForm.value.capacity
    // };    
    // console.log('formData*******************',formData);
    // if (this.addproductForm.invalid) {
    //   return;
    // }else{
      this.commonService.addProduct(formData).subscribe(data => { console.log("Data Object ",data )});
      this.addproductForm.reset();
    // }
    
  }
  toggleVisibility(e){
    this.marked= e.target.checked;
  }

}
