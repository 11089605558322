import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service'
import { Router } from '@angular/router';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.css']
})
export class ProductDetailComponent implements OnInit {
  product;
  public safeURL: SafeResourceUrl;
  baseUrl = environment.ApiPath;

  constructor(private commonService: CommonService ,private router: Router,private route: ActivatedRoute,private _sanitizer: DomSanitizer) { }

  ngOnInit() {
    var id = this.route.snapshot.paramMap.get('id');
    console.log("ROUTER",id);
    this.commonService.getProductById(id).subscribe(data => {
      this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl(data.data.videoUrl);
      this.product = data.data;
      window.scrollTo(0, 0);
    });
  }

}
