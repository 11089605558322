import { Component, OnInit } from '@angular/core';
import { CommonService } from '../common.service'
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-contactus',
  templateUrl: './contactus.component.html',
  styleUrls: ['./contactus.component.css']
})
export class ContactusComponent implements OnInit {
  contactusForm: FormGroup;
  constructor(private toastr: ToastrService,private formBuilder: FormBuilder, private router: Router,private http: HttpClient ,  private commonService: CommonService) { }

  ngOnInit() {
    this.contactusForm = this.formBuilder.group({
      userName: ['', Validators.required],
      userEmail: ['', Validators.required],
      phoneNumber: ['', Validators.required],
      requestDescription: ['', Validators.required],
    });
  }
  onSubmit() {
   
    console.log('formData*******************',this.contactusForm.value);
    if (this.contactusForm.invalid) {
      return;
    }else{
      this.commonService.addContactus(this.contactusForm.value).subscribe(data => { 
        this.toastr.show('', 'Thank you for expressing your interest in our company , we will reach back to you soon .');
        this.router.navigate(["/"])
        // console.log("Data Object ",data )
      
      });
      // this.contactusForm.reset();
    }
    
  }

}
